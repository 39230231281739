import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Home" />
      <h1>Administrative Guide for SIMC website</h1>
      <p>
        Welcome to the User Guide! This serves as a reference for you to find
        instructions for the various actions available to you on the CMS.
      </p>
      <p>
        The website is based on Wordpress, using {` `}
        <a href="https://www.upstatement.com/timber/">Timber</a> to create a
        modern, lightweight theme that is easily maintainable.
      </p>
      <p>Here is a list of plugins that is bundled with your custom theme:</p>
      <ul>
        <li>
          Advanced Custom Fields PRO
          <br />
          Adds custom fields into post types like Posts, Events, Panelists,
          Pages
        </li>
        <li>
          Autoptimize
          <br />
          Optimises the website's various CSS & HTML files by minifying it so
          that it loads faster
        </li>
        <li>
          Contact Form 7, Flamingo & Listo
          <br />
          Lightweight contact form plugin and companion plugin to list all
          contact form submissions and add list of countries to form
        </li>
        <li>
          Duplicate Post
          <br />
          Adds "Clone" functionality to post types
        </li>
        <li>
          Event Tickets
          <br />
          Allows you to sell tickets and collect RSVPs from events
        </li>
        <li>
          Menu Icons
          <br />
          Adds menu icons to the navigation menu
        </li>
        <li>
          Regenerate Thumbnails
          <br />
          Allows you to mass-regenerate thumbnails of uploaded media
        </li>
        <li>
          Search & Filter Pro
          <br />
          Creates a filter bar for your visitors
        </li>
        <li>
          The Events Calendar
          <br />
          Adds "Event" functionality to your website
        </li>
        <li>
          Timber
          <br />
          Required plugin to work with your custom theme
        </li>
        <li>
          Ultimate Addons for Gutenberg
          <br />
          Extends the Gutenberg editor with important blocks that are currently
          missing from the default editor
        </li>
        <li>
          WP Super Cache
          <br />
          Caching plugin that generates static HTML pages and stores them to
          serve to visitors. This speeds up pageload times significantly.
        </li>
      </ul>
      <p>
        In general, the more plugins you install on Wordpress, the slower it
        becomes. It is <strong>highly</strong> recommended that before you
        install a plugin, you consider the potential effects it has on the
        performance of your website. When in doubt, feel free to{" "}
        <a href="mailto:alfred@sulphur.com.sg">contact Sulphur</a>.
      </p>
    </Layout>
  )
}

export default IndexPage
